import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Center, Divider, Heading, Hide, Show } from '@chakra-ui/react';
import { Pizza, Popcorn, Wine } from 'phosphor-react';
import { RecommendationWidget } from 'exoclick-react';

import {
  BaseHeading,
  BasePagination,
  BaseScene,
  BaseSeo,
} from '@components/base';
import { TapeFilters, TapeList } from '@components/tape';

export default function TapeListQuerylessTemplate({
  pageContext: { tapes, numPages, currentPage, toPath, baseTitle },
}) {
  // Generate page title
  const title = React.useMemo(() => {
    const pageText = currentPage > 1 ? `, Page ${currentPage}` : '';
    return `${baseTitle}${pageText}`;
  }, [currentPage, baseTitle]);
  // Generate [tapes] with tags
  const currentTapes = React.useMemo(
    () =>
      tapes.map((edge, i) => ({ ...edge.node, isLazyLoading: i > 2 })) || [],
    [tapes]
  );

  return (
    <BaseScene as='section'>
      <Show above='2sm'>
        <TapeFilters activeSort={toPath} />
      </Show>
      <Show above='2sm'>
        <Heading as='h1' mt={4} pb={6} textAlign='center'>
          {title}
        </Heading>
      </Show>
      <Hide above='2sm'>
        <FilterHeading activeSort={toPath} title={title} />
      </Hide>
      <TapeList tapes={currentTapes} tapeTitleProps={{ as: 'h2' }} />
      <Center pt={4} pb={2}>
        <RecommendationWidget zoneId={4856200} />
      </Center>
      {!!tapes.length && (
        <BasePagination
          mb={2}
          numPages={numPages}
          currentPage={currentPage}
          toPath={toPath}
        />
      )}
      <Divider />
    </BaseScene>
  );
}

export function Head({ data, pageContext }) {
  const { pathname } = useLocation();
  const { site } = data;
  const { currentPage, baseTitle, tapes } = pageContext;
  const { siteUrl, siteTitle, datePublished } = site.siteMetadata;

  const title = React.useMemo(() => {
    const pageText = currentPage > 1 ? `, Page ${currentPage}` : '';
    return `${baseTitle}${pageText}`;
  }, [currentPage, baseTitle]);

  const dateModified = React.useMemo(() => {
    const latestTape =
      tapes.length &&
      tapes.sort(
        (a, b) => new Date(b.node.created_at) - new Date(a.node.created_at)
      )[0];

    if (currentPage <= 5) {
      return new Date().toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }

    return latestTape?.node.created_at || datePublished;
  }, [tapes, datePublished]);

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `${title} ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, title, datePublished, dateModified]);

  return (
    <BaseSeo
      title={title}
      description={`View ${title}`}
      schemaGraphs={schemaGraphs}
    />
  );
}

export const query = graphql`
  query siteQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        datePublished
      }
    }
  }
`;

function RFilterHeading({ activeSort, title }) {
  const icon = React.useMemo(
    () =>
      ({
        '/newest': Popcorn,
        '/trending': Pizza,
        '/longest': Wine,
      }[activeSort]),
    [activeSort]
  );

  return (
    <>
      <BaseHeading as='h1' icon={icon}>
        {title}
      </BaseHeading>
      <Divider mb={4} />
    </>
  );
}

const FilterHeading = React.memo(RFilterHeading);
